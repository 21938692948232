//系统识别
function system_confirm(){var userAgentInfo = navigator.userAgent;if(userAgentInfo.indexOf("Win")>-1){return "win"}else if(userAgentInfo.indexOf("Mac")>-1){return "mac"}};function device_confirm(){var userAgentInfo = navigator.userAgent;var Agents = ["Android", "iPhone","SymbianOS", "Windows Phone","iPad", "iPod"];var flag = true;for (var v = 0; v < Agents.length; v++) {if (userAgentInfo.indexOf(Agents[v]) > 0) {flag = false;break;}}return flag;};
if(system_confirm()=="mac"&&device_confirm()){var isMac=true}
else if(system_confirm()=="win"&&device_confirm()){var isMac=false}
if(device_confirm()==true){var device_id=true}
else if(device_confirm()==false){var device_id=false}else{};
function system_fn(){
    if (isMac == true) {
        $('.sys_mac').addClass('active').siblings('.sys_win').removeClass('active');
        $('.mac_btn_box').css({"display": "-webkit-box","display":"-webkit-flex","display":"flex"});
        $('.mac_btn').css({"display":"block"});
        $('.mac_inline').css({"display":"inline-block"});
        $('.win_inline').css({"display":"none"});
        $('.win_btn_box').css({"display":"none"});
        $('.win_btn').css({"display":"none"});
    } else if (!isMac == true) {
        $('.sys_win').addClass('active').siblings('.sys_mac').removeClass('active');
        $('.win_btn_box').css({"display": "-webkit-box","display":"-webkit-flex","display":"flex"});
        $('.win_btn').css({"display":"block"});
        $('.mac_inline').css({"display":"none"});
        $('.win_inline').css({"display":"inline-block"});
        $('.mac_btn_box').css({"display":"none"});
        $('.mac_btn').css({"display":"none"});
    } else if (device_id == false) {
        $('.pc_btn').css({"display":"none"});
        $('.mac_inline').css({"display":"none"});
        $('.win_inline').css({"display":"inline-block"});
    };
};
system_fn();

// 上导航
$('.header .toggle').click(function(){
    $(this).toggleClass('active')
    $('.header .header_nav').toggle()
})
function header_resize(){
    if($(window).width()>1200){
        $('.header .toggle').removeClass('active')
        $('.header .header_nav').show()
    }else{
        $('.header .toggle').removeClass('active')
        $('.header .header_nav').hide()
    }
}
header_resize()
// 修改宽度重新运行
var editor_widht_fn=[]
var window_width=$(window).width();
$(window).resize(function(){
    if(window_width!=$(window).width()){
        // 多处理的函数
        for(var i=0;i<editor_widht_fn.length;i++){
            editor_widht_fn[i]()
        }
        window_width=$(window).width()
    }
})
editor_widht_fn.push(function(){
    header_resize()
})
// 下导航
$('.footer .box dl dt').click(function(){
    $(this).parent().toggleClass('active')
})
$('.footer .box .language>span').click(function(){
    $(this).toggleClass('active')
    $('.footer .box .language_box').toggle()
})
// 订阅
$('.footer .box .subscribe_btn').click(function(){
    $('.subscribe_mask').show()
    $('.subscribe_box div .error_p').hide()
    $('.subscribe_box div input').val('')
})
$('.subscribe_box .close').click(function(){
    $('.subscribe_mask').hide()
})
var reg_email = /^[a-zA-Z0-9.!#$%&#$%&amp; '*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
$('.subscribe_box div .btn').click(function(){
    if(reg_email.test($('.subscribe_box div input').val())){
        $.post('https://www.streambyte.net/api/subscribe.php',{
            email:$('.subscribe_box div input').val(),
            source:1,
        },function(res){
            console.log(res)
        })
        $('.subscribe_mask').hide()
        $('.subscribe_box div input').val('')
    }else{
        $('.subscribe_box div .error_p').show()
    }
})